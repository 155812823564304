import React from "react";
import Navigation from "../components/Navigation";
import ContactForm from "../components/ContactForm";
import Footer from "../components/Footer";

const Profile = () => {
  return (
    <div>
      <div id="wrapper">
        <header id="header" className="alt">
          <nav>
            <a href="#menu">Menu</a>
          </nav>
        </header>

        <Navigation />

        <section id="banner" className="major">
          <div className="inner">
            <header className="major">
              <h1>Here is a bit about me</h1>
            </header>

            <div className="content">
              <p>
                I have been studying web development since December of 2019. I
                absolutly fell in love with it when I started at Lambda School,
                an online program that offers a lot more than a bootcamp, but
                not quite as much as a traditional college. I received my
                endorsement in Web Development and Computer Science in April of
                2021.
              </p>
            </div>
          </div>
        </section>

        <div id="main">
          <section id="two" className="spotlights">
            <section>
              <span className="image">
                <img
                  src="images/pic08.jpg"
                  alt=""
                  data-position="center center"
                />
              </span>
              <div className="content">
                <div className="inner">
                  <header className="major">
                    <h3>Skills</h3>
                  </header>

                  <div className="skills">
                    <ul>
                      <li>React.js</li>
                      <li>HTML/CSS</li>
                      <li>Javascript</li>
                      <li>Node.js</li>
                      <li>Building RESTful apis</li>
                    </ul>
                    <ul>
                      <li>Object Oriented Programming</li>
                      <li>Python</li>
                      <li>Data Structures and Algorithms</li>
                      <li>Scrum</li>
                      <li>Postman</li>
                    </ul>
                    <ul>
                      <li>MySQl/SQL/Postgres</li>
                      <li>Jest</li>
                      <li>Unit Testing</li>
                      <li>etc...</li>
                    </ul>
                  </div>
                </div>
              </div>
            </section>
            <section>
              <span className="image">
                <img src="images/pic09.jpg" alt="" data-position="top center" />
              </span>
              <div className="content">
                <div className="inner">
                  <header className="major">
                    <h3>Experience</h3>
                  </header>
                  <p>
                    Most of my work experience has been through Jimmy Johns as I
                    have been there since July of 2014. I have held every
                    position exept regional manager and have performed above and
                    beyond expectatons.<br></br>
                    <br></br>I have, however, gained some amazing experience
                    during my time at Lambda. The final part of the program is
                    called Labs. The purpose of labs is to give you as much
                    "professional" experience as possible in the shortest amount
                    of time. Think of labs kind of like an internship: you are
                    placed with a team of varying technical ability to work on
                    an already existing code base. This presents its own
                    challenges like giving and receiving feedback, dealing with
                    merge conflicts, reviewing each others code, and more. We
                    were also given the opportunity to work with an external
                    stakeholder. The company I got to work with is called{" "}
                    <a href="https://www.villagebookbuilders.org/?v=1d20b5ff1ee9">
                      Village Book Builders
                    </a>
                    . I gained experience taking something from an ambiguous
                    idea, to a plan, to fully functional code.
                  </p>
                  <ul className="actions">
                    <li></li>
                  </ul>
                </div>
              </div>
            </section>
            <section>
              <span className="image">
                <img src="images/pic10.jpg" alt="" data-position="25% 25%" />
              </span>
              <div className="content">
                <div className="inner">
                  <header className="major">
                    <h3>Final Thoughts</h3>
                  </header>
                  <p>
                    If you have made it this far, I appreciate the time you took
                    to get to know me a little bit! If you have any questions or
                    feedback, feel free to reach out using the contact form at
                    the bottom of the page!
                  </p>
                </div>
              </div>
            </section>
          </section>
        </div>

        <section id="contact">
          <ContactForm />
        </section>

        <Footer />
      </div>
    </div>
  );
};

export default Profile;
