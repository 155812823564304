import React from "react";
import emailjs from "emailjs-com";
import { useState } from "react";

const ContactForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const validForm = name && email && message;

  function sendEmail(e) {
    e.preventDefault();

    const formData = {
      name,
      email,
      message,
    };

    emailjs
      .send(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        formData,
        process.env.REACT_APP_USER_ID
      )
      .then(
        result => {
          console.log(result.text);
        },
        error => {
          console.log(error.text);
        }
      );

    setName("");
    setEmail("");
    setMessage("");
  }

  return (
    <div className="inner">
      <section>
        <form onSubmit={sendEmail}>
          <div className="fields">
            <div className="field half">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                name="name"
                id="name"
                value={name}
                onChange={e => setName(e.target.value)}
              />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                name="email"
                id="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea
                name="message"
                id="message"
                rows="6"
                value={message}
                onChange={e => setMessage(e.target.value)}
              ></textarea>
            </div>
          </div>
          <ul className="actions">
            <li>
              <input
                type="submit"
                value="Send Message"
                className="primary"
                disabled={!validForm}
              />
            </li>
            <li>
              <input type="reset" value="Clear" />
            </li>
          </ul>
        </form>
      </section>
      <section className="split">
        <section>
          <div className="contact-method">
            <span className="icon solid alt fa-envelope"></span>
            <h3>Email</h3>
            <p>jtwillo51@gmail.com</p>
          </div>
        </section>
        <section>
          <div className="contact-method">
            <span className="icon solid alt fa-phone"></span>
            <h3>Phone</h3>
            <span>(801) 205-7381</span>
          </div>
        </section>
        <section>
          <div className="contact-method">
            <span className="icon solid alt fa-envelope"></span>
            <h3>Resume</h3>
            <a
              href="https://drive.google.com/file/d/1A-eQ8FKi4yNeoSB6pDQdh_fDOnI4yx8W/view?usp=sharing"
              target="_blank"
            >
              View in Drive
            </a>
          </div>
        </section>
      </section>
    </div>
  );
};

export default ContactForm;
