import React from "react";

const Footer = () => {
  return (
    <footer id="footer">
      <div className="inner">
        <ul className="icons">
          <li>
            <a
              href="https://github.com/jtwillo51"
              className="icon brands alt fa-github"
            >
              <span className="label">GitHub</span>
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/in/jeremy-willoughby/"
              className="icon brands alt fa-linkedin-in"
            >
              <span className="label">LinkedIn</span>
            </a>
          </li>
        </ul>
        <ul className="copyright">
          <li>&copy; Untitled</li>
          <li>
            Design: <a href="https://html5up.net">HTML5 UP</a>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
