import React from "react";
import { Link } from "react-router-dom";
import UseTech from "../images/Use-my-tech.png";
import AirBnB from "../images/Airbnb-price.png";
import Recipes from "../images/recipes-BE-code.png";
import GOL from "../images/conways-GOL.png";
import POS from "../images/PosadmintoolVPSscreen.png";

import Navigation from "../components/Navigation";
import ContactForm from "../components/ContactForm";
import Footer from "../components/Footer";

const Projects = () => {
  return (
    <div>
      <div id="wrapper">
        <header id="header">
          <nav>
            <a href="#menu">Menu</a>
          </nav>
        </header>

        <Navigation />

        <div id="main" className="alt">
          <section id="one">
            <div className="inner">
              <header className="major">
                <h1>Projects</h1>
              </header>
              <span className="image main">
                <img src="images/pic11.jpg" alt="" />
              </span>
              <p>
                Here are some of the projects I have built over the past year or
                so. For most of these, I worked with a team of developers. I
                have worked in both <br></br>front-end and back-end roles. I
                have always taken pride in what I can do, but I also know I have
                much more to learn.
              </p>
            </div>
          </section>
          <section id="two" className="spotlights">
            <section>
              <a href="##" className="image">
                <img
                  src={POS}
                  alt="app landing page"
                  data-position="top center"
                />
              </a>
              <div className="content">
                <div className="inner">
                  <header className="major">
                    <h3>POS Admin tool</h3>
                  </header>
                  <p>
                    This is a desktop application that is used to check which
                    franchise locations have settings that differ from the
                    standard set by corporate. The application also has the
                    ability to consolidate that data into a downloadable PDF.
                    The application also has the ability to search orders and
                    will return a list of items that most closely fit the
                    criteria. Due to the private nature of this project, I can
                    only show so much, however, I am more than happy to answer
                    any questions you may have.
                  </p>
                  <p className="project-tech">
                    Built using: React, CSS, Node, Express, Electron
                  </p>
                  <ul className="actions"></ul>
                </div>
              </div>
            </section>
            <section>
              <a
                href="https://github.com/BW-Use-My-Tech-Stuff-1/Front-End"
                className="image"
              >
                <img
                  src={UseTech}
                  alt="Camera listing for rent"
                  data-position="center center"
                />
              </a>
              <div className="content">
                <div className="inner">
                  <header className="major">
                    <h3>Use my tech stuff!</h3>
                  </header>
                  <p>
                    For this project, I was acting as the front-end architect
                    leading two other react developers. I delegated and helped
                    build the compontents neccessary, then hooked them all
                    together and tested functionality. The purpose of this
                    project was to create a site where users could list items
                    for rent or sale. The main target audiece would be
                    photographers, DJs, musicians, etc.
                  </p>
                  <p className="project-tech">
                    Built using: React, Redux, Jest, RTL, CSS
                  </p>
                  <ul className="actions">
                    <li>
                      <a
                        href="https://github.com/BW-Use-My-Tech-Stuff-1/Front-End"
                        className="button"
                      >
                        View Code
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </section>
            <section>
              <a
                href="https://github.com/BWBC-AirBnBOptimalPrice/AirBnB-fe"
                className="image"
              >
                <img
                  src={AirBnB}
                  alt="app landing page"
                  data-position="top center"
                />
              </a>
              <div className="content">
                <div className="inner">
                  <header className="major">
                    <h3>AirBnB Optimal Price</h3>
                  </header>
                  <p>
                    This is a SPA designed with homeowners in mind. The idea was
                    you could input your homes location, features, etc. And the
                    app will suggest a price for you to list your home at on
                    AirBnB or other similar sites. I was personally responsible
                    for all of the forms needed for creating an account, logging
                    in, and listing a home. I also built almost everything but
                    the state management system.
                  </p>
                  <p className="project-tech">
                    Built using: React, Formik, CSS
                  </p>
                  <ul className="actions">
                    <li>
                      <a
                        href="https://github.com/BWBC-AirBnBOptimalPrice/AirBnB-fe"
                        className="button"
                      >
                        View Code
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </section>
            <section>
              <a
                href="https://github.com/Secret-Familiy-Recipes/Unit4-Backend"
                className="image"
              >
                <img src={Recipes} alt="Node code" data-position="25% 25%" />
              </a>
              <div className="content">
                <div className="inner">
                  <header className="major">
                    <h3>Secret Family Recipes</h3>
                  </header>
                  <p>
                    This was my final build week with a team. As the backend
                    engineer, I was responsible for building out the database,
                    API, and basic security. The purpose of this project was to
                    give families a place to store, manage, and search through
                    recipes. I used migrations and seeds for the database,
                    bcrypt with a time-delay for password storage, and JSON web
                    tokens to keep a user logged in.
                  </p>
                  <p className="project-tech">
                    Built using: Node.js, SQLite, Express, Knex.js, superTest,
                    PostMan
                  </p>
                  <ul className="actions">
                    <li>
                      <a
                        href="https://github.com/Secret-Familiy-Recipes/Unit4-Backend"
                        className="button"
                      >
                        View Code
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </section>
            <section>
              <Link to="/generic" className="image">
                <img
                  src={GOL}
                  alt="conways game of life"
                  data-position="top center"
                />
              </Link>
              <div className="content">
                <div className="inner">
                  <header className="major">
                    <h3>Conway's Game of Life</h3>
                  </header>
                  <p>
                    This project is a two-dimentional grid where the state of
                    each cell (known as a cellular automaton) can change from
                    one generation to the next, based on the state of its
                    neighbors. This was a solo project meant to boost my
                    knowledge of computing logic, runtime complexity, and space
                    complexity.
                  </p>
                  <p className="project-tech">
                    Built using: React, react-bootstrap, bootstrap, HTML, CSS,
                    Javascript
                  </p>
                  <ul className="actions">
                    <li>
                      <a
                        href="https://github.com/jtwillo51/Conways-Game-Of-Life"
                        className="button"
                      >
                        View Code
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </section>
          </section>
        </div>

        <section id="contact">
          <ContactForm />
        </section>

        <Footer />
      </div>
    </div>
  );
};

export default Projects;
