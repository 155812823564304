import React from "react";
import GOL from "../images/conways-GOL.png";
import { Link } from "react-router-dom";
import Navigation from "../components/Navigation";
import ContactForm from "../components/ContactForm";
import Footer from "../components/Footer";

const Home = () => {
  return (
    <div>
      <div id="wrapper">
        <header id="header" className="alt style2">
          <nav>
            <a href="#menu">Menu</a>
          </nav>
        </header>
        <Navigation />
        <section id="banner" className="style2">
          <div className="inner">
            <span className="image">
              <img src="images/pic07.jpg" alt="" />
            </span>
            <header className="major">
              <h1>Hey, I'm Jeremy</h1>
            </header>
            <div className="content">
              <p>
                I'm a Software Engineer with a passion for clean and maintainable code. I am always focused on learning
                something new, and gaining a mastery of the skills I already possess.
              </p>
            </div>
          </div>
        </section>

        <div id="main">
          <section id="one">
            <div className="inner">
              <header className="major">
                <h2>About me</h2>
              </header>
              <p>
                I was born and raised in Utah, graduating from high school in
                2016. I discovered a love of coding in 2019 and engaged with
                Lambda School (now BloomTech) to learn Full Stack Web Development and Computer
                Science. I got married in February of 2021 and finished my
                program in April of that year. We then moved from Utah to Arizona for 2 years before finally ending up in Tennessee. We enjoy hiking, fishing, and working with various farm animals.
              </p>
            </div>
          </section>

          <section id="two" className="spotlights">
            <section>
              <Link to="/projects" className="image">
                <img src={GOL} alt="" data-position="center center" />
              </Link>
              <div className="content">
                <div className="inner">
                  <header className="major">
                    <h3>See my work</h3>
                  </header>
                  <p>
                    I have compiled some projects I take pride in. They were
                    built using everything from raw HTML and CSS to React.js and
                    Node.js. Each project will have instructions in the READ.ME
                    on how to get it installed and started so you can see it
                    work.
                  </p>
                  <ul className="actions">
                    <li>
                      <Link to="/projects" className="button">
                        Learn more
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </section>
          </section>
        </div>

        <section id="contact">
          <ContactForm />
        </section>

        <Footer />
      </div>
    </div>
  );
};

export default Home;
